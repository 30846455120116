import {
  Box,
  Breadcrumbs,
  Button,
  ButtonBase,
  Chip,
  Divider,
  Grid,
  Link as MuiLink,
  Modal,
  Skeleton,
  Stack,
  Tab,
  Tabs,
  useMediaQuery,
  CircularProgress,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import DateRangeIcon from '@mui/icons-material/DateRange';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ReceiptIcon from '@mui/icons-material/Receipt';
import QueryBuilderOutlinedIcon from '@mui/icons-material/QueryBuilderOutlined';
import './CompanyJobDetails.scss';
import {
  getPaymentStatus,
  JobStatuses,
  JobTypes,
  JobTypesFullName,
  PaymentType,
  ProposalStatus,
  ProposalStatuses,
  StudentTypes,
  useCancelJobByUid,
  useCompleteJob,
  useCreatOtjInvoice,
  useFetchJobById,
  useFetchJobHired,
  useFetchJobProposals,
  usePayJob,
} from '../../data/jobs';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { Routes } from '../../utils/consts';
import MiniteGrid from '../../components/grid';
import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
} from '@mui/x-data-grid';
import ProposalStatusChip from './components/ProposalStatusChip';
import TimesheetIcon from '@mui/icons-material/Schedule';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import RepeatIcon from '@mui/icons-material/Repeat';
import { JobDrawer } from '../../components/job-drawer';
import ProposalCompanyView from './components/ProposalCompanyView';
import {
  fetchChatByProposalId,
  fetchProposalById,
  useDuplicateProposalRequest,
} from '../../data/proposals';
import { budgetString } from './index';
import JobStatusChip from './components/JobStatusChip';
import { useTranslation } from 'react-i18next';
import { handleGridHeight } from '../../utils/helpers';
import GenericModal from '../../components/generic-modal/GenericModal';
import ReviewModal from '../../components/review-modal/ReviewModal';
import { useSetReviewJob } from '../../data/timesheets';
import MiniteSnackbar from '../../components/snackbar';
import SkeletonProposalModal from './SkeletonProposalModal';
import { openChatUrl } from '../../utils/openChat';
import CheckIcon from '@mui/icons-material/Check';
import PromoButtons, { PromoType } from '../../components/promo-buttons';
import { GridActionsColDef } from '@mui/x-data-grid/models/colDef/gridColDef';
import { formatDate } from '../../utils/dates';
import RehireStudentModal from './components/RehireStudentModal';
import CircularProgressWithLabel from '../../components/talents/CircularProgressWithLabel';
import CancelReasonModal from './components/CancelReasonModal';

const TabsValues = {
  ALL: 'All',
  PENDING: ProposalStatuses.Pending,
  INTERVIEWING: ProposalStatuses.Interviewing,
  ACCEPTED: ProposalStatuses.Accepted,
  REJECTED: ProposalStatuses.Rejected,
};

function a11yProps(index: number) {
  return {
    id: `proposal-tab-${index}`,
    'aria-controls': `proposal-tabpanel-${index}`,
  };
}

const boostJobAllowed = {
  [JobStatuses.INTERVIEWING]: true,
  [JobStatuses.DRAFT]: false,
  [JobStatuses.PENDING_APPROVAL]: true,
};

const proposalsGridData = (proposals, jobType, budget) =>
  proposals?.map((proposal) => {
    return {
      id: proposal?.proposal_uid,
      jobUid: proposal?.job_uid,
      isTimesheet:
        proposal?.proposal_status === ProposalStatuses.Signed &&
        jobType === JobTypes.ONGOING,
      studentUid: proposal?.student_uid,
      status: proposal?.proposal_status,
      highFlyer: proposal?.student_name,
      email: proposal?.email,
      phone: proposal?.phone,
      offer: budgetString(proposal?.rate || budget, jobType),
      actions: '',
      mobileActions: '',
      matchScore: proposal?.match_score,
    };
  }) || [];

const CompanyJobDetails = () => {
  const { t } = useTranslation();
  const params = useParams<{ jobUid?: string }>();
  const {
    data: jobData,
    isLoading,
    refetch: refetchJob,
  } = useFetchJobById(params.jobUid);
  const {
    data: proposals,
    isLoading: isLoadingProposals,
    refetch: reloadProposals,
  } = useFetchJobProposals(params.jobUid);
  const {
    data: hired,
    isLoading: isLoadingHired,
    refetch: reloadHired,
  } = useFetchJobHired(params.jobUid);
  const {
    mutateAsync: cancelJobByUid,
    isSuccess,
    isLoading: isLoadingCancelation,
  } = useCancelJobByUid();
  const { mutateAsync: completeJob, isLoading: isLoadingCompleteJob } =
    useCompleteJob();
  const { mutateAsync: setReviewJob, isLoading: isLoadingReview } =
    useSetReviewJob();
  const { mutateAsync: duplicateProposalRequest } =
    useDuplicateProposalRequest();
  const [proposal, setProposal] = useState(null);
  const [completedModalShown, setCompletedModalShown] = useState(false);
  const [endJobModalShown, setEndJobModalShown] = useState(false);
  const [isShowToast, setIsShowToast] = useState(false);
  const [isEndJob, setIsEndJob] = useState(false);
  const isMobileView = useMediaQuery('(max-width: 600px)');
  const isTableView = useMediaQuery('(max-width: 1050px)');
  const [isActionsOpen, setActionsOpen] = useState(false);
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);
  const navigate = useNavigate();
  const [selectedTab, selectTab] = React.useState(TabsValues.ALL);
  const [proposalsTab, setProposalsFiltered] = React.useState([]);
  const [filterRows, setFilterRows] = React.useState([]);
  const [shownProposalDrawer, setProposalDrawerShow] = useState(false);
  const [ratingValue, setRatingValue] = useState(null);
  const [isRatingOpen, setIsRatingOpen] = useState(false);
  const [isCancellableJob, setIsCancellableJob] = useState(false);
  const [modalType, setModalType] = useState('cancel');
  const [isSkeleton, setIsSkeleton] = useState(false);
  const [successSnack, showSnack] = useState(false);
  const [isBoostModal, setIsBoostModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isSuccessBoost, setIsSuccess] = useState(null);
  const { mutateAsync: payJob, isLoading: isPayingJob } = usePayJob();
  const [promoType, setPromoType] = useState(PromoType.Boost);
  const [isBoostedJob, setIsBoostedJob] = useState(false);
  const [isProposalLoading, setIsProposalLoading] = useState(false);
  const [jobStartDate, setJobStartDate] = useState(null);
  const [rehireModalShown, setRehireModalShown] = useState(false);
  const [isSpinnerShown, setIsSpinnerShown] = useState(false);
  const [isCancelReasonModalShown, setIsCancelReasonModalShown] =
    useState(false);
  const [cancelReasonState, setCancelReasonState] = useState({
    cancel: () => {
      console.log('Cancel called without initializing');
    },
    confirm: (reason: string, endDate: string) => {
      console.log('Confirm called without initializing, reason: ' + reason);
      console.log('Confirm called without initializing, end date: ' + endDate);
    },
    title: t('job_details.cancel_job_reason_modal.title_cancel'),
    description: t('job_details.cancel_job_reason_modal.description_cancel'),
  });
  const { mutateAsync: creatOtjInvoice } = useCreatOtjInvoice();

  const urlParams = new URLSearchParams(window.location.search);
  const rehire_job_uid = urlParams.get('rehire_uid');

  useEffect(() => {
    if (!jobData || !jobData.job_uid) {
      return;
    }
    const checkPaymentStatus = async () => {
      if (rehire_job_uid) {
        setIsSpinnerShown(true);
        const responseStatus = await getPaymentStatus(
          rehire_job_uid,
          PaymentType.PostingFee,
        );
        if (responseStatus === 'paid') {
          try {
            const res_proposal = await duplicateProposalRequest({
              jobUid: jobData.job_uid,
              body: {
                new_job_uid: rehire_job_uid,
              },
            });
            window.open(
              '/Agreement/' + res_proposal.proposal.proposal_uid,
              '_self',
            );
          } catch (error) {
            setErrorMessage('Could not autogenerate proposal');
          } finally {
            setIsSpinnerShown(false);
          }
        } else {
          setIsSpinnerShown(false);
          setErrorMessage('Something went wrong during payment');
        }
      }
    };
    checkPaymentStatus().catch(console.error);
  }, [jobData]);

  const interviewsInProgressCount = proposals?.filter(
    (proposal) => proposal?.proposal_status === ProposalStatuses.Interviewing,
  ).length;
  const acceptedCount = proposals?.filter(
    (proposal) => proposal?.proposal_status === ProposalStatuses.Accepted,
  ).length;

  // Handlers

  useEffect(() => {
    if (
      !jobData?.reviewed &&
      JobStatuses.COMPLETED === jobData?.job_status &&
      JobTypes.ONGOING !== jobData?.job_type
    ) {
      setIsRatingOpen(true);
    }

    if (jobData?.job_start_at) {
      const startDate = new Date(jobData.job_start_at);
      const now = new Date();

      if (now.getTime() < startDate.getTime()) {
        setJobStartDate(formatDate(jobData.job_start_at, 'DD MMMM YYYY'));
      } else {
        setJobStartDate('ASAP');
      }
    } else {
      setJobStartDate('-');
    }

    if (jobData?.job_boost_pack) {
      setIsBoostedJob(!!Number(jobData?.job_boost_pack));
    }
  }, [jobData]);

  const handleActionsOpen = () => setActionsOpen(true);

  const handleActionsClose = () => setActionsOpen(false);

  const handleCancelDialogOpen = () => {
    let type = 'cancel';
    const { job_type, job_status } = jobData;

    if (
      (job_type === JobTypes.PREMIUM || job_type === JobTypes.ONGOING) &&
      (job_status === JobStatuses.OPEN ||
        job_status === JobStatuses.INTERVIEWING)
    ) {
      type = 'areYouSure';
    }

    setModalType(type);
    setIsCancelDialogOpen(true);
  };

  const showCancelJobReasonModal = (
    title,
    description,
    confirmAction,
    cancelAction?,
  ) => {
    setIsCancelReasonModalShown(true);
    setCancelReasonState({
      confirm: (reason, endDate) => {
        setIsCancelReasonModalShown(false);
        confirmAction(reason, endDate);
      },
      cancel: () => {
        setIsCancelReasonModalShown(false);
        if (cancelAction) {
          cancelAction();
        }
      },
      title,
      description,
    });
  };

  const sendCancelJobRequest = (reason: string) => {
    cancelJobByUid({ jobUid: jobData.job_uid, reason: reason }).then(() => {
      const time = setInterval(() => {
        clearTimeout(time);
      }, 400);

      refetchJob();
      reloadProposals();
      reloadProposals();
    });
  };

  const handleEditJobClick = () => {
    if (jobData.direct_job) {
      navigate(`${Routes.JobDirectPostRoute}/${jobData.job_uid}`, {
        replace: true,
      });
    } else {
      navigate(`${Routes.JobPostRoute}/${jobData.job_uid}`, { replace: true });
    }
  };

  const handleTabSelect = (event: React.SyntheticEvent, value: string) => {
    selectTab(value);
  };

  const getProposal = async (uid: string) => {
    try {
      const { proposal } = await fetchProposalById(uid);
      setProposal(proposal);
    } catch (error) {
      setErrorMessage(
        error.response?.data?.error.message || error?.message?.toString(),
      );
    }
  };

  const handleProposalDrawerOpen = async (uid: string) => {
    setProposalDrawerShow(true);
    setIsSkeleton(true);
    await getProposal(uid);
    setIsSkeleton(false);
  };
  /**
   * Reloads the currently selected proposal when
   * proposal list is changed
   */
  useEffect(() => {
    (async () => {
      if (proposal) {
        await getProposal(proposal.proposal_uid);
      }
    })();
  }, [proposals]);

  const handleTimesheetOpen = (jobUid: string, studentUid: string) => {
    const path = generatePath(Routes.TimesheetStudentRoute, {
      jobUid,
      studentUid,
    });
    navigate(path, {
      replace: true,
    });
  };

  const cancelJobTranslation = {
    cancel: {
      title: 'Cancel job',
      content:
        'You’re about to cancel this job. Are you sure? Note that this action is irreversible. You will not be able to hire any student anymore for this job.',
    },
    areYouSure: {
      title: 'Are you sure?',
      content:
        'Jobs that are already in Open or Interviewing stage cannot be automatically cancelled. If you proceed, you will be contacted by Minite first to discuss retracting this job. This will also reject all students who have submitted a proposal.',
    },
  };

  const handleChatOpen = (proposal_uid: string) => {
    openChatUrl(proposal_uid);
  };

  const handleProposalDrawerClose = (event) => {
    event?.preventDefault();
    reloadProposals();
    reloadHired();
    refetchJob();
    setProposalDrawerShow(false);
  };

  const handleAgreementNavigate = (proposal_uid: string) => {
    navigate(Routes.AgreementRoute + proposal_uid);
  };

  const showEmailAndPhone = jobData?.job_status === JobStatuses.IN_PROGRESS;

  // Grid

  const hiredColumns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      hide: true,
    },
    {
      field: 'status',
      headerName: 'Status',
      sortable: false,
      width: 150,
      renderCell: (params: GridRenderCellParams<ProposalStatus>) => (
        <ProposalStatusChip proposalStatus={params.value} />
      ),
    },
    {
      field: 'highFlyer',
      headerName: 'High-Flyer',
      flex: 1,
      sortable: true,
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 2,
      sortable: true,
      hide: !showEmailAndPhone,
      renderCell: (params: GridRenderCellParams) => (
        <a href={`mailto:${params.value}`}>{params.value}</a>
      ),
    },
    {
      field: 'phone',
      headerName: 'Phone',
      flex: 1,
      sortable: true,
      hide: !showEmailAndPhone,
    },
    {
      field: 'actions',
      headerName: '',
      width: 340,
      sortable: false,
      align: 'right',
      hide: isTableView,
      renderCell: (params: GridRenderCellParams) => (
        <div className="actions-bar">
          {params.row.isTimesheet && (
            <Button
              className="timesheet-button"
              data-testid="open-timesheet"
              onClick={() =>
                handleTimesheetOpen(params.row.jobUid, params.row.studentUid)
              }
              sx={{
                color: '#DEB900',
                fontSize: 13,
                fontFamily: '"Sofia W05 Semi Bold", sans-serif',
              }}
              startIcon={<TimesheetIcon />}
            >
              Timesheet
            </Button>
          )}

          <Button
            className="chat-button"
            data-testid="open-chat"
            onClick={() => handleChatOpen(params.row.id)}
            sx={{
              color: '#DEB900',
              fontSize: 13,
              fontFamily: '"Sofia W05 Semi Bold", sans-serif',
            }}
            startIcon={<ChatBubbleOutlineOutlinedIcon />}
          >
            Chat
          </Button>

          <Button
            onClick={() => handleProposalDrawerOpen(params.row.id)}
            data-testid="view-proposal"
            sx={{
              color: '#DEB900',
              fontSize: 13,
              fontFamily: '"Sofia W05 Semi Bold", sans-serif',
            }}
          >
            {jobData?.direct_job
              ? t('proposal.preview_profile')
              : t('proposal.view_proposal')}{' '}
            <ChevronRightOutlinedIcon
              sx={{
                color: '#DEB900',
                cursor: 'pointer',
              }}
              className="next-button"
            />
          </Button>
        </div>
      ),
    },
    {
      field: 'mobileActions',
      type: 'actions',
      headerName: '',
      sortable: false,
      align: 'right',
      width: 80,
      hide: !isTableView,
      getActions: (params) => {
        const actions = [];

        if (params.row.isTimesheet) {
          actions.push(
            <GridActionsCellItem
              sx={{
                textTransform: 'capitalize',
              }}
              data-testid="open-timesheet"
              onClick={() =>
                handleTimesheetOpen(params.row.jobUid, params.row.studentUid)
              }
              label="Timesheet"
              showInMenu
            />,
          );
        }
        actions.push(
          <GridActionsCellItem
            sx={{
              textTransform: 'capitalize',
            }}
            onClick={() => handleChatOpen(params.row.id)}
            label="Chat"
            data-testid="open-chat"
            showInMenu
          />,
        );

        return actions;
      },
    } as GridActionsColDef,
  ];

  const getIsChat = (status, id) => {
    const isShow = jobData?.job_type !== JobTypes.ONGOING;
    const notShowChat = {
      [ProposalStatuses.Rejected]: true,
      [ProposalStatuses.Retracted]: true,
    };

    if (isShow && !notShowChat[status]) {
      return fetchChatByProposalId(id);
    }

    return null;
  };

  useEffect(() => {
    const gridData = proposalsGridData(
      proposalsTab,
      jobData?.job_type,
      jobData?.budget,
    );

    const chats = gridData.reduce(
      (acc, item) => [...acc, getIsChat(item.status, item.id)],
      [],
    );

    setIsProposalLoading(true);

    Promise.allSettled(chats)
      .then((res) => {
        const copy = structuredClone(gridData);

        res.forEach((item, index) => {
          copy[index].isChat = !!(
            item.status === 'fulfilled' && item.value?.success
          );
        });

        setFilterRows(copy.sort((a, b) => b.isChat - a.isChat));
        setIsProposalLoading(false);
      })
      .catch(() => {
        setIsProposalLoading(false);
        setFilterRows(gridData);
      });
  }, [proposalsTab]);

  const ChatButton = ({
    status,
    id,
    isChat,
  }: {
    status: string;
    id: string;
    isChat: boolean;
  }) => {
    const statuses = {
      [ProposalStatuses.Pending]: true,
      [ProposalStatuses.Rejected]: true,
    };

    const isShow = !jobData?.direct_job;

    return (
      ((isShow && !statuses[status]) || isChat) && (
        <Button
          className="chat-button"
          onClick={() => handleChatOpen(id)}
          data-testid="open-chat"
          sx={{
            color: '#DEB900',
            fontSize: 13,
            fontFamily: '"Sofia W05 Semi Bold", sans-serif',
          }}
          startIcon={<ChatBubbleOutlineOutlinedIcon />}
        >
          Chat
        </Button>
      )
    );
  };

  const proposalsColumns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      hide: true,
    },
    {
      field: 'status',
      headerName: 'Status',
      sortingOrder: ['asc', 'desc'],
      width: 150,
      renderCell: (params: GridRenderCellParams<ProposalStatus>) => (
        <a onClick={() => handleProposalDrawerOpen(params.row.id)}>
          <ProposalStatusChip proposalStatus={params.value} />
        </a>
      ),
    },
    {
      field: 'matchScore',
      headerName: 'Match',
      hide: jobData?.direct_job,
      sortingOrder: ['asc', 'desc'],
      renderCell: (params: GridRenderCellParams<number>) => (
        <CircularProgressWithLabel value={params.value} />
      ),
    },
    {
      field: 'highFlyer',
      headerName: 'High-Flyer',
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <a
          style={{ cursor: 'pointer' }}
          onClick={() => handleProposalDrawerOpen(params.row.id)}
        >
          {params.value}
        </a>
      ),
    },
    {
      field: 'offer',
      headerName: 'Offer',
      width: 100,
      hide: jobData?.direct_job,
      sortingOrder: ['asc', 'desc'],
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 2,
      sortable: true,
      hide: !(jobData?.direct_job && jobData?.job_status === JobStatuses.OPEN),
      renderCell: (params: GridRenderCellParams) => (
        <a
          href={`mailto:${
            jobData?.direct_job &&
            (params.row.status === ProposalStatuses.Interviewing ||
              params.row.status === ProposalStatuses.Accepted)
              ? params.value
              : ''
          }`}
        >
          {jobData?.direct_job &&
          (params.row.status === ProposalStatuses.Interviewing ||
            params.row.status === ProposalStatuses.Accepted)
            ? params.value
            : ''}
        </a>
      ),
    },
    {
      field: 'phone',
      headerName: 'Phone',
      flex: 1,
      sortable: true,
      hide: !(jobData?.direct_job && jobData?.job_status === JobStatuses.OPEN),
      renderCell: (params: GridRenderCellParams) => (
        <span>
          {jobData?.direct_job &&
          (params.row.status === ProposalStatuses.Interviewing ||
            params.row.status === ProposalStatuses.Accepted)
            ? params.row.phone
            : ''}
        </span>
      ),
    },
    {
      field: 'actions',
      headerName: '',
      width: 245,
      sortable: false,
      align: 'right',
      renderCell: (params: GridRenderCellParams) => (
        <div className="actions-bar">
          {params.row.status !== ProposalStatuses.Draft &&
            params.row.status !== ProposalStatuses.Retracted && (
              <>
                <ChatButton
                  status={params.row.status}
                  id={params.row.id}
                  isChat={params.row.isChat}
                />
                <Button
                  onClick={() => handleProposalDrawerOpen(params.row.id)}
                  data-testid="view-proposal"
                  sx={{
                    color: '#DEB900',
                    fontSize: 13,
                    fontFamily: 'Sofia W05 Semi Bold, sans-serif',
                  }}
                >
                  {jobData?.direct_job
                    ? t('proposal.preview_profile')
                    : t('proposal.view_proposal')}{' '}
                  <ChevronRightOutlinedIcon
                    sx={{
                      color: '#DEB900',
                      cursor: 'pointer',
                    }}
                    className="next-button"
                  />
                </Button>
              </>
            )}
        </div>
      ),
    },
  ];

  const invoiceColumns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      hide: true,
    },
    {
      field: 'invoice_number',
      headerName: 'Invoice number',
      width: 200,
    },
    {
      field: 'created_at',
      headerName: 'Creation date',
      width: 200,
    },
    {
      field: 'paid_status',
      headerName: 'Paid status',
      width: 200,
      renderCell: (params: GridRenderCellParams<ProposalStatus>) => (
        <>
          {params.value ? (
            <DoneIcon sx={{ color: '#14DB87' }} />
          ) : (
            <CloseIcon sx={{ color: '#EA4335' }} />
          )}
        </>
      ),
    },
  ];

  const invoicesGridData = (): unknown[] => {
    const onetimeInvoices =
      jobData.invoices &&
      jobData.invoices
        ?.filter((invoice) => invoice.invoice_number !== null)
        ?.filter(
          (invoice) =>
            invoice.invoice_number[0] === 'H' ||
            invoice.invoice_number[0] === 'I',
        )
        ?.map((invoice) => {
          return {
            id: invoice.invoice_uid,
            invoice_number: invoice.invoice_number,
            created_at: formatDate(invoice.created_at, 'DD/MM/YYYY'),
            paid_status: invoice.invoice_paid,
            student_uid: null,
          };
        });
    const timesheetInvoices =
      jobData.timesheets &&
      jobData.timesheets
        ?.filter((t) => t.invoice_sent)
        ?.map((t) => {
          return {
            id: t.timesheet_uid,
            invoice_number: t.company_collective_invoice_number,
            created_at: formatDate(t.created_at, 'DD/MM/YYYY'),
            paid_status: t.invoice_paid,
            student_uid: t.student_uid,
          };
        });
    if (onetimeInvoices && timesheetInvoices) {
      return timesheetInvoices.concat(onetimeInvoices);
    } else if (onetimeInvoices) {
      return onetimeInvoices;
    } else {
      return timesheetInvoices;
    }
  };

  // Effects

  useEffect(() => {
    if (!isLoadingProposals) {
      if (selectedTab === TabsValues.ALL) {
        setProposalsFiltered(proposals);
      } else {
        const filtered =
          proposals?.filter(
            (proposal) => proposal?.proposal_status === selectedTab,
          ) || [];
        setProposalsFiltered(filtered);
      }
    }
  }, [proposals, isLoadingProposals, selectedTab]);

  useEffect(() => {
    switch (jobData?.job_status) {
      case JobStatuses.DRAFT:
      case JobStatuses.PENDING_APPROVAL:
      case JobStatuses.OPEN:
      case JobStatuses.INTERVIEWING:
        setIsCancellableJob(true);
        break;
      default:
        setIsCancellableJob(false);
    }

    setIsEndJob(
      jobData?.job_status === JobStatuses.IN_PROGRESS &&
        !!jobData?.requested_to_complete_at,
    );
  }, [jobData]);

  useEffect(() => {
    if (localStorage.getItem('paid')) {
      localStorage.removeItem('paid');

      (async () => {
        try {
          const response = await getPaymentStatus(
            params.jobUid,
            PaymentType.BoostPackFee,
          );

          if (response === 'paid') {
            setIsSuccess(true);
            setIsBoostedJob(true);
          } else {
            setIsBoostedJob(false);
            setErrorMessage(
              t('create_job.step_review.could_not_process_your_payment'),
            );
          }
        } catch (e) {
          console.error(e);
        }
      })();
    }
  }, []);

  if (isLoading || jobData === undefined) {
    return (
      <Stack spacing={1}>
        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />

        <Skeleton variant="circular" width={80} height={80} />
        <Skeleton variant="rectangular" width={400} height={60} />
        <Skeleton variant="rounded" width={400} height={60} />
      </Stack>
    );
  }

  const navigateToDashboard = () => {
    navigate(`${Routes.DashboardRoute}?company`);
  };

  const editable =
    jobData.job_status === JobStatuses.DRAFT ||
    jobData.job_status === JobStatuses.PENDING_APPROVAL;

  const completable = jobData.job_status === JobStatuses.IN_PROGRESS;

  const endJob =
    jobData.job_status === JobStatuses.IN_PROGRESS &&
    JobTypes.ONGOING === jobData.job_type;

  const handleViewAsAStudent = () => {
    window.open(
      generatePath(Routes.CompanyStudentJobDetails, {
        jobUid: jobData?.job_uid,
      }),
      '_blank',
    );
  };

  const confirmComplete = () => {
    completeJob({ jobUid: jobData.job_uid }).then(() => {
      refetchJob();
      reloadProposals();
      creatOtjInvoice({ job_uid: jobData.job_uid });
      setCompletedModalShown(false);
      setIsRatingOpen(true);
    });
  };

  const handleOpenEndJobModal = () => {
    setEndJobModalShown(!endJobModalShown);
  };

  const sendEndJobRequest = (reason: string, endDate: string) => {
    completeJob({
      jobUid: jobData.job_uid,
      reason: reason,
      endDate: endDate,
    }).then(() => {
      handleOpenEndJobModal();
      setIsShowToast(true);
      setIsEndJob(true);
      refetchJob();
    });
  };

  const submitRating = (review) => {
    setReviewJob({
      description: review,
      rating: ratingValue,
      job_uid: jobData.job_uid,
    });
  };

  const handleBoostJob = () => {
    setIsBoostModal(!isBoostModal);
  };

  const handleClick = (type: PromoType) => {
    setPromoType(type);
  };

  const handlePayPromoPack = async () => {
    if (promoType === PromoType.Boost) {
      try {
        const response = await payJob({
          jobUid: jobData.job_uid,
          body: {
            job_boost_pack: 50,
            payment_type: PaymentType.BoostPackFee,
          },
        });

        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });

        localStorage.setItem('paid', '1');
        window.open(response.data.checkout_url, '_self');
      } catch (error) {
        setErrorMessage('Failed to generate payment link for job!');
      }
    } else {
      setIsBoostModal(false);
    }
  };

  const subCategory = jobData.sub_category.code
    ? t(`sub_categories.${jobData.sub_category.code}`)
    : jobData.sub_category.sub_category_name || '';

  return (
    <Grid
      container
      direction="row"
      className="job-details-company-view-container"
      alignItems="stretch"
    >
      {isSpinnerShown && (
        <div className="spinner-overlay">
          <CircularProgress style={{ color: '#deb900' }} />
        </div>
      )}
      <Grid item md={9} xs={12} className="job-details">
        <div className="navigation">
          <Breadcrumbs>
            <MuiLink onClick={navigateToDashboard}>Dashboard</MuiLink>
            <p>
              Job:{' '}
              {jobData?.job_status === JobStatuses.DRAFT && !jobData.job_title
                ? 'Draft'
                : jobData.job_title}
            </p>
          </Breadcrumbs>

          {isMobileView && (
            <ButtonBase onClick={handleActionsOpen} data-testid="open-actions">
              <MoreVertIcon />
            </ButtonBase>
          )}
        </div>
        <div className="top">
          <h2 className="job-title heading-text">
            {jobData?.job_status === JobStatuses.DRAFT && !jobData.job_title
              ? 'Draft'
              : jobData.job_title}
          </h2>

          <JobStatusChip jobStatus={jobData.job_status} />
        </div>

        <Divider />

        <section className="main">
          <div className="left">
            <h2 className="job-type-title heading-text">
              {jobData.direct_job
                ? jobData.direct_job_type === 'Inhouse day' ||
                  jobData.direct_job_type === 'Event'
                  ? 'Event'
                  : 'Direct job'
                : JobTypesFullName[jobData.job_type]}
            </h2>
            {jobData.direct_job && (
              <>
                <p className="info-title medium-text">
                  Job type:{' '}
                  <span className="info-value">{jobData.direct_job_type}</span>
                </p>
                {jobData.office_location && (
                  <p className="info-title">
                    {t('job_details.office_location')}:{' '}
                    <span className="info-value location">
                      {jobData.office_location}
                    </span>
                  </p>
                )}
                {jobData.job_start_at && (
                  <p className="info-title">
                    {t('Event date')}:{' '}
                    <span className="info-value">
                      {jobData.job_start_at.substring(0, 10)}
                    </span>
                  </p>
                )}
              </>
            )}
            {!jobData.direct_job && (
              <>
                {!jobData.parent_job_uid && (
                  <p className="info-title medium-text">
                    {t('job_details.hiring')}:{' '}
                    <span className="info-value">
                      {jobData.students_needed} High-Flyer's |{' '}
                      {jobData.students_applied}/{jobData.students_needed} hired
                    </span>
                  </p>
                )}

                <p className="info-title medium-text">
                  {t('job_details.category')}:{' '}
                  <span className="value info-value">
                    {t(`categories.${jobData.category.code}`)}
                    {jobData.sub_category &&
                      !!subCategory &&
                      ` | ${subCategory}`}
                  </span>
                </p>

                <p className="info-title medium-text">
                  {t('job_details.langs')}:{' '}
                  <span className="info-value">
                    {jobData.languages
                      ?.map((item) => item.language_name)
                      .join(', ')}
                  </span>
                </p>

                <p className="info-title medium-text">
                  {jobData?.job_type === JobTypes.ONGOING
                    ? t('job_details.hours_per_week')
                    : t('job_details.estimate_hours_needed')}
                  :{' '}
                  <span className="info-value">{jobData.hours_estimated}</span>
                </p>

                <p className="info-title medium-text">Skills:</p>
                <div className="skills-container medium-text">
                  {jobData.skills?.map((skill) => (
                    <Chip
                      className="medium-text skill"
                      key={skill.skill_uid}
                      label={skill.skill_name}
                      sx={{
                        width: 'auto',
                        height: 32,
                        backgroundColor: '#F2F9FC',
                        color: '#393A42',
                        fontSize: '12px',
                        fontWeight: 500,
                        lineHeight: '20px',
                        fontFamily: 'Sofia W05 Medium, sans-serif',
                      }}
                    />
                  ))}
                </div>
              </>
            )}
            {jobData?.job_title?.includes('In-house on 30th September') ? (
              <></>
            ) : (
              <p className="info-title medium-text">
                Viewed{' '}
                <span className="info-value">
                  {jobData.views ? jobData.views : 0}
                </span>{' '}
                {parseInt(jobData.views) === 1 ? 'time' : 'times'}
              </p>
            )}
          </div>

          <div className="right">
            {jobData.direct_job ? (
              <div className="budget-container">
                <span className="budget heading-text lower">
                  {jobData.direct_job_compensation}
                </span>
              </div>
            ) : (
              <div className="budget-container">
                <span className="budget heading-text lower">
                  {budgetString(jobData.budget, jobData.job_type)}
                </span>
              </div>
            )}
            {!jobData.direct_job && (
              <>
                {jobData.job_type === JobTypes.ONGOING &&
                  jobData.student_type === StudentTypes.EntryLevel && (
                    <Chip
                      className="student-type medium-text"
                      label={jobData.student_type}
                      variant="outlined"
                    />
                  )}

                <p className="info-title medium-text">
                  {t('job_details.location')}:{' '}
                  <span className="info-value medium-text location">
                    {jobData.job_location}
                  </span>
                </p>
                {jobData.office_location && (
                  <p className="info-title">
                    {t('job_details.office_location')}:{' '}
                    <span className="info-value location">
                      {jobData.office_location}
                    </span>
                  </p>
                )}

                <p className="info-title">
                  {t('job_details.start_date')}:{' '}
                  <span className="info-value">{jobStartDate}</span>
                </p>
              </>
            )}

            <p className="info-title medium-text address-info">
              <span>
                {jobData.address.street_name} {jobData.address.house_number},
              </span>
              <br />
              <span>
                {jobData.address.postal_code} {jobData.address.city}
              </span>
            </p>
          </div>
        </section>
        <div className="description">
          <p className="text medium-text job-details-description">
            {jobData.job_description}
          </p>
        </div>

        {
          <>
            <Divider />

            {hired?.length > 0 && (
              <div className="proposals">
                <h3 className="heading-text">Hired</h3>

                <MiniteGrid
                  height={handleGridHeight(hired?.length || 0, 8)}
                  loading={isLoadingHired}
                  columns={hiredColumns}
                  rows={proposalsGridData(
                    hired,
                    jobData?.job_type,
                    jobData?.budget,
                  )}
                  rowId={'id'}
                  disableColumnFilter={true}
                />
              </div>
            )}

            <div className="proposals">
              <h3 className="heading-text">Proposals</h3>

              {!isMobileView && (
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    width: '100%',
                  }}
                >
                  <Tabs
                    className="proposal-tabs"
                    value={selectedTab}
                    onChange={handleTabSelect}
                  >
                    {Object.keys(TabsValues).map((key, index) => {
                      return (
                        <Tab
                          className={
                            TabsValues[key] === selectedTab
                              ? 'proposal-tab selected'
                              : 'proposal-tab'
                          }
                          key={key}
                          label={TabsValues[key]}
                          value={TabsValues[key]}
                          {...a11yProps(index)}
                        />
                      );
                    })}
                  </Tabs>
                </Box>
              )}

              {!(isLoadingProposals || isProposalLoading) &&
              !proposals?.length ? (
                <p className="placeholder medium-text">
                  You don't have any proposals submitted yet. Complete your job,
                  so students can create their proposal!
                </p>
              ) : (
                <MiniteGrid
                  pagination
                  loading={isLoadingProposals || isProposalLoading}
                  columns={proposalsColumns}
                  rows={filterRows}
                  rowId={'id'}
                  disableColumnFilter={true}
                />
              )}
            </div>
          </>
        }

        {
          <>
            <div className="proposals">
              <h3 className="heading-text">Invoices</h3>
              <MiniteGrid
                height={handleGridHeight(jobData.invoices?.length || 0, 8)}
                columns={invoiceColumns}
                rows={invoicesGridData()}
                rowId={'id'}
                disableColumnFilter={true}
              />
            </div>
          </>
        }
      </Grid>
      {!isMobileView && (
        <Grid item md={3} xs={12}>
          <div className="actions-container">
            <h3 className="title heading-text">{t('proposal.actions')}</h3>
            {boostJobAllowed[jobData?.job_status] && (
              <ButtonBase
                onClick={handleBoostJob}
                className="action medium-text completed"
                disabled={isBoostedJob}
                data-testid="boost-job"
              >
                <CheckIcon className="icon completed" />
                <p className="completed">
                  {isBoostedJob
                    ? t('job_details.job_boosted')
                    : t('job_details.boost_job')}
                </p>
              </ButtonBase>
            )}
            <ButtonBase
              onClick={handleViewAsAStudent}
              className="action medium-text"
              data-testid="view-job-as-student"
            >
              <DateRangeIcon className="icon" />
              <p>{t('job_details.view_job_as_student')}</p>
            </ButtonBase>
            {editable && (
              <ButtonBase
                className="action medium-text"
                onClick={handleEditJobClick}
                data-testid="edit-job"
              >
                <EditIcon className="icon" />
                <p>{t('job_details.edit_job')}</p>
              </ButtonBase>
            )}
            {!proposal?.legacy_id && hired?.length > 0 && (
              <ButtonBase
                aria-label="agreement"
                className="action medium-text"
                onClick={() => handleAgreementNavigate(hired[0].proposal_uid)}
              >
                <ReceiptIcon className="icon" />
                <p>
                  {hired[0].proposal_status === ProposalStatuses.Signed ||
                  proposal?.signed_by_company_at
                    ? 'View'
                    : 'Sign'}{' '}
                  agreement
                </p>
              </ButtonBase>
            )}
            {hired?.length > 0 &&
              hired[0].proposal_status === ProposalStatuses.Signed &&
              jobData.job_type === JobTypes.ONGOING && (
                <ButtonBase
                  onClick={() =>
                    handleTimesheetOpen(hired[0].job_uid, hired[0].student_uid)
                  }
                  aria-label="log"
                  className="action medium-text"
                >
                  <QueryBuilderOutlinedIcon className="icon" />
                  <p>{t('proposal.view_timesheet')}</p>
                </ButtonBase>
              )}
            {((jobData.job_type === JobTypes.ONE_TIME &&
              jobData.job_status === JobStatuses.IN_PROGRESS) ||
              jobData.job_status === JobStatuses.COMPLETED) && (
              <ButtonBase
                className="action medium-text"
                data-testid="rehire-student-job"
                onClick={() => setRehireModalShown(true)}
              >
                <RepeatIcon className="icon rehire" />
                <p className="rehire">{t('job_details.rehire_student')}</p>
              </ButtonBase>
            )}
            {completable &&
              (JobTypes.ONE_TIME === jobData.job_type ||
                JobTypes.PREMIUM === jobData.job_type) && (
                <ButtonBase
                  className="action medium-text"
                  data-testid="mark-as-completed-job"
                  onClick={() => setCompletedModalShown(true)}
                >
                  <DoneIcon className="icon completed" />
                  <p className="completed">Mark as completed</p>
                </ButtonBase>
              )}
            {endJob && !isEndJob && (
              <ButtonBase
                className="action medium-text"
                onClick={handleOpenEndJobModal}
                data-testid="end-job"
              >
                <CloseIcon className="icon close" />
                <p className="close">{t('job_details.end_job')}</p>
              </ButtonBase>
            )}
            {isEndJob && <p className="close">Job ending soon</p>}
            {isCancellableJob && (
              <ButtonBase
                className="action medium-text"
                onClick={handleCancelDialogOpen}
                data-testid="cancel-job"
              >
                <CloseIcon className="icon close" />
                <p className="close">{t('job_details.cancel_job')}</p>
              </ButtonBase>
            )}
            <>
              {jobData.in_progress_at && (
                <>
                  <hr />
                  <p className="close">
                    Started at:{' '}
                    {formatDate(jobData.in_progress_at, 'DD MMMM YYYY')}
                  </p>
                </>
              )}
              {jobData.completed_at && (
                <>
                  <p className="close">
                    Completed at:{' '}
                    {formatDate(jobData.completed_at, 'DD MMMM YYYY')}
                  </p>
                </>
              )}
            </>
          </div>
        </Grid>
      )}
      <MiniteSnackbar
        severity={'success'}
        message={'Your request has been received, thank you!'}
        autoHideDuration={4000}
        open={isShowToast}
        onClose={() => setIsShowToast(false)}
      />
      {isMobileView && (
        <Modal open={isActionsOpen} onClose={handleActionsClose}>
          <div className="actions-container modal">
            <div className="modal-top">
              <h3 className="title heading-text">{t('dashboard.actions')}</h3>

              <ButtonBase
                onClick={handleActionsClose}
                data-testid="close-actions"
              >
                <CloseIcon />
              </ButtonBase>
            </div>
            {boostJobAllowed[jobData?.job_status] && (
              <ButtonBase
                onClick={handleBoostJob}
                className="action medium-text completed"
                disabled={isBoostedJob}
                data-testid="boost-job"
              >
                <CheckIcon className="icon completed" />
                <p className="completed">
                  {isBoostedJob
                    ? t('job_details.job_boosted')
                    : t('job_details.boost_job')}
                </p>
              </ButtonBase>
            )}

            <ButtonBase
              className="action medium-text"
              onClick={handleViewAsAStudent}
              data-testid="view-job-as-student"
            >
              <DateRangeIcon className="icon" />
              <p>{t('job_details.view_job_as_student')}</p>
            </ButtonBase>

            <ButtonBase
              className="action medium-text"
              onClick={handleEditJobClick}
              data-testid="edit-job"
            >
              <EditIcon className="icon" />
              <p>{t('job_details.edit_job')}</p>
            </ButtonBase>

            <ButtonBase
              className="action medium-text"
              onClick={handleCancelDialogOpen}
              data-testid="cancel-job"
            >
              <CloseIcon className="icon close" />
              <p className="close">{t('job_details.cancel_job')}</p>
            </ButtonBase>
          </div>
        </Modal>
      )}
      <GenericModal
        open={completedModalShown}
        title={t('job_completed.mark_completed')}
        content={t('job_completed.mark_completed_q')}
        cancelButtonText={t('job_completed.cancel')}
        confirmButtonText={t('job_completed.confirm')}
        handleCancel={() => setCompletedModalShown(!completedModalShown)}
        handleConfirm={confirmComplete}
        loadingButton
        isLoading={isLoadingCompleteJob}
        confirmButtonTestId="complete-job-confirm"
      />
      <GenericModal
        open={isCancelDialogOpen}
        {...cancelJobTranslation[modalType]}
        cancelButtonText={t('job_completed.cancel')}
        confirmButtonText={isSuccess ? 'Success' : t('job_completed.confirm')}
        handleCancel={() => setIsCancelDialogOpen(!isCancelDialogOpen)}
        handleConfirm={() => {
          setIsCancelDialogOpen(!isCancelDialogOpen);
          showCancelJobReasonModal(
            t('job_details.cancel_job_reason_modal.title_cancel'),
            t('job_details.cancel_job_reason_modal.description_cancel'),
            sendCancelJobRequest,
          );
        }}
        loadingButton
        isLoading={isLoadingCancelation}
        confirmButtonTestId="cancel-job"
      />
      <GenericModal
        open={endJobModalShown}
        title={t('job_completed.end_ongoing_job')}
        content={t('job_completed.agreement_note')}
        cancelButtonText={t('job_completed.cancel')}
        confirmButtonText={t('job_completed.confirm')}
        handleCancel={handleOpenEndJobModal}
        handleConfirm={() => {
          handleOpenEndJobModal();
          showCancelJobReasonModal(
            'Job end details',
            t('job_details.cancel_job_reason_modal.description_end'),
            sendEndJobRequest,
          );
        }}
        isLoading={isLoadingCompleteJob}
        confirmButtonTestId="end-job-confirm"
        loadingButton
      />
      <GenericModal
        open={isBoostModal}
        title={t('job_details.boost_your_job')}
        cancelButtonText={t('job_completed.cancel')}
        confirmButtonText={t('job_completed.confirm')}
        handleCancel={handleBoostJob}
        handleConfirm={handlePayPromoPack}
        isLoading={isPayingJob}
        confirmButtonTestId="complete-job-confirm"
        loadingButton
      >
        <PromoButtons onClick={handleClick} type={PromoType.Boost} />
      </GenericModal>
      <RehireStudentModal
        open={rehireModalShown}
        handleClose={() => {
          setRehireModalShown(false);
        }}
        jobData={jobData}
      />
      <CancelReasonModal
        open={isCancelReasonModalShown}
        title={cancelReasonState.title}
        description={cancelReasonState.description}
        handleClose={cancelReasonState.cancel}
        handleConfirm={cancelReasonState.confirm}
        jobData={jobData}
      />
      <ReviewModal
        title={t('job_completed.job_completed')}
        content={[
          `${t('job_completed.please_tell_us')}`,
          t('job_completed.by_sharing'),
        ]}
        ratingValue={ratingValue}
        handleRatingValue={setRatingValue}
        isOpen={isRatingOpen}
        onClose={() => setIsRatingOpen(false)}
        onSubmit={submitRating}
        isLoading={isLoadingReview}
      />
      <MiniteSnackbar
        open={successSnack}
        title={'Woohoo!'}
        message={t('proposal.interview_successful')}
        autoHideDuration={4000}
        severity={'success'}
        onClose={() => showSnack(null)}
      />
      <MiniteSnackbar
        open={!!errorMessage}
        title={'Error'}
        message={errorMessage}
        autoHideDuration={4000}
        onClose={() => setErrorMessage(null)}
      />
      <MiniteSnackbar
        open={isSuccessBoost}
        title={'Success!'}
        severity={'success'}
        message={t('proposal.successful_payment')}
        autoHideDuration={4000}
        onClose={() => setIsSuccess(null)}
      />
      <JobDrawer
        open={shownProposalDrawer}
        onClose={handleProposalDrawerClose}
        className="job-drawer-company--wrapper"
      >
        {isSkeleton ? (
          <SkeletonProposalModal />
        ) : (
          <ProposalCompanyView
            job={jobData}
            proposal={proposal}
            interviewsCount={interviewsInProgressCount}
            hiredCount={acceptedCount}
            handleDrawerClose={handleProposalDrawerClose}
            showSnack={showSnack}
          />
        )}
      </JobDrawer>
    </Grid>
  );
};

export default CompanyJobDetails;
